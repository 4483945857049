"use client"
// import * as Sentry from "@sentry/nextjs"

import Button from "@modules/common/components/button"
import { useEffect } from "react"

export default function Error({
  reset,
  error,
}: {
  reset: () => void
  error?: any
}) {
  useEffect(() => {
    // Sentry.captureException(error)
  }, [error])

  return (
    <div className="mx-auto my-4 flex max-w-xl flex-col rounded-lg border border-neutral-200 bg-white p-8 md:p-12">
      <h2 className="text-xl font-bold">Å nej!</h2>
      <p className="my-2">
        Något gick fel med vår butik. Detta kan vara en tillfälligt fel, försök
        igen senare.
      </p>
      <Button variant="primary" onClick={() => reset()}>
        Försök igen
      </Button>
    </div>
  )
}
